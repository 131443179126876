<template>
  <div>
    <!-- <div class="fl">
      <span class="ml heads-span"
        >{{ $t("productManagement.category") }} :
      </span>
      <el-select
        v-model="category"
        :placeholder="$t('productManagement.whole')"
        class="select"
      ></el-select>
    </div>
    <div class="fr">
      <span class="heads-span">{{ $t("productManagement.brand") }} : </span>
      <el-select
        v-model="brand"
        :placeholder="$t('productManagement.whole')"
        class="select"
      ></el-select>
    </div>
    <div class="clear"></div>
    <div class="fl mt ml heads-span">
      <span>{{ $t("productManagement.attribute") }} : </span>
      <el-select
        v-model="attribute"
        :placeholder="$t('productManagement.whole')"
        class="select"
      ></el-select>
    </div>
    <div class="fr mt ml">
      <el-button class="query">{{ $t("productManagement.query") }}</el-button>
      <el-button class="refresh" type="primary">{{
        $t("productManagement.refresh")
      }}</el-button>
    </div>
    <div class="clear"></div> -->
    <!-- picture -->
    <el-card class="role">
      <el-row>
        <el-col :span="3" class="p-left picture">
          <span>{{ $t("productManagement.picture") }}</span>
        </el-col>
        <el-col :span="7" class="p-left">
          <span>{{ $t("productManagement.title") }}</span>
        </el-col>
        <el-col :span="3">
          <span>{{ $t("productManagement.price") }}($)</span>
        </el-col>
        <el-col :span="6">
          <span>{{ $t("productManagement.category") }}</span>
        </el-col>
        <el-col :span="3">
          <span>{{ $t("productManagement.status") }}</span>
        </el-col>
        <el-col :span="2">
          <span>{{ $t("productManagement.operation") }}</span>
        </el-col>
      </el-row>
    </el-card>

    <el-card
      class="mt1 headquarters"
      v-for="(item, index) in data"
      :key="index"
    >
      <el-row>
        <el-col :span="3" class="mt1 p-left">
          <img :src="item.gallery[0].img" class="img" v-if="item.gallery[0]" />
          <img src="@/assets/default_brand_logo.png" class="img" v-else />
        </el-col>
        <el-col :span="7" class="mt p-left">
          <p>{{ item.productName }}</p>
        </el-col>
        <el-col :span="3" class="mt">
          <span>{{ item.price }}</span>
        </el-col>
        <el-col :span="6" class="mt">
          <span>{{ $t("category." + item.categoryName) }}</span>
        </el-col>
        <el-col :span="3" class="mt">
          <el-switch
            v-model="item.status"
            @change="switchs(item._id, item.status)"
            switch
            active-color="#FF0A0A"
            inactive-color="gray"
          >
          </el-switch>
        </el-col>
        <el-col :span="2" class="edit" style="padding-top: 20px">
          <button class="p1" @click="edit(item._id)">
            {{ $t("common.edit") }}
          </button>
          <br />
          <!-- <button disabled v-if="item.img == '@/assets/default_brand_logo.png'">
            {{ $t("common.view") }}
          </button> -->
          <button class="p3" @click="view(item._id)">
            {{ $t("common.view") }}
          </button>
          <br />
          <button class="p2" @click="deletes(item._id)">
            {{ $t("common.delete") }}
          </button>
        </el-col>
      </el-row>
    </el-card>

    <div class="pagination-rounded" v-if="totalRows > perPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="perPage"
        layout="total, prev, pager, next, jumper"
        :total="totalRows"
        jumper-label="dd"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { deleteData, getLists, setStatus } from "../../helper/api";
export default {
  data() {
    return {
      status: 1,
      category: "",
      brand: "",
      attribute: "",
      data: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageSizes: [1, 2, 3, 4, 5],
    };
  },
  created() {
    this.productList(this.currentPage);
  },
  methods: {
    view(id) {
      window.open("https://sokogate.com/product-detail/" + id);
    },
    productList(page) {
      getLists("get", "product", page, "desc").then((res) => {
        // console.log(res);
        this.data = [];
        res.data.rows.forEach((item) => {
          item.status = item.status == 0 ? true : false;
          this.data.push(item);
        });
        this.totalRows = res.data.count;
      });
    },
    switchs(id, status) {
      var set_status = status == true ? 0 : 1;
      setStatus("PUT", "product", id, set_status).then(() => {
        // console.log(res);
      });
    },

    edit(id) {
      this.$router.push("productEdit?product_id=" + id);
    },

    deletes(id) {
      //this.data.splice(index, 1);

      this.$confirm(this.$t("common.confirmDelete"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.ok"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      })
        .then(() => {
          deleteData("DELETE", "product", id).then(() => {
            this.$message({
              message: this.$t("common.deleteSuccess"),
              type: "success",
            });
          });

          this.productList(this.currentPage);
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage = 1;
      this.productList(this.currentPage);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.productList(this.currentPage);
    },
  },
};
</script>

<style scoped>
.select {
  width: 300px;
  margin-left: 5px;
  margin-right: 150px;
}
.query {
  width: 100px;
  background: #ff0a0a;
  color: #fff;
  margin-right: 40px;
}
.refresh {
  width: 150px;
  color: #fff;
  margin-right: 10px;
}
.role {
  background: #ffdcbc;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.role .picture {
  padding-left: 15px;
}
.headquarters {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.headquarters .delete {
  color: #ff0a0a;
}
.headquarters .p1 {
  color: #ff0a0a;
  background: none;
  cursor: pointer;
}
.headquarters .p3 {
  color: #ff0a0a;
  background: none;
  cursor: pointer;
  margin-top: 5px;
}
.headquarters .p2 {
  margin-top: 5px;
  color: gray;
  background: none;
  cursor: pointer;
}
.headquarters .edit {
  margin-top: -20px;
}
.p-left {
  text-align: left;
}
.p-center {
  text-align: center;
}
.img {
  width: 55px;
  height: 48px;
}
.heads-span {
  font-size: 16px;
}
.pagination-rounded {
  height: 40px;
  line-height: 40px;
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 10px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}
</style>